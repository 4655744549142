import Dropdown from "app/components/molecules/dropdown";
import LabelSyncStatus from "app/components/molecules/labels/labelSyncStatus";
import ModalConfirm from "app/components/molecules/modals/modalConfirm";
import ModalPostType from "app/components/molecules/modals/modalPostType";
import BuilderConstructor from "app/components/organisms/builder";
import { useDeletePostTypeMutation, useGetPostTypeQuery, useUpdatePostTypeMutation, useUpdatePostTypeStructureMutation } from "app/stores/builder";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "app/components/atoms/loading";
import { i18n } from "app/i18n";

export default function PostTypeEdit(props) {
  const { id, reloadList } = props;

  // PARAMS
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // STATES
  const [modal, setModal] = useState(null);
  const [confirmModal, setConfirmModal] = useState(null);

  // REQUESTS
  const [updatePostType, { isLoading: isUpdating }] = useUpdatePostTypeMutation();
  const [updateStructure, { isLoading: isUpdatingStructure, isError }] = useUpdatePostTypeStructureMutation();
  const [deletePostType, { isLoading: isDeleting }] = useDeletePostTypeMutation();
  const { data, isFetching, isLoading: isGetting, refetch } = useGetPostTypeQuery({ id });

  // VARS
  const isLoading = isDeleting || isUpdating || isGetting || isUpdatingStructure;

  useEffect(() => {
    refetch();
  }, [pathname]);

  // ON UPDATE SETTINGS
  const onEdit = () => {
    setModal({
      isOpen: true,
      postType: data,
      buttonText: i18n("button.update_postType"),
      onSubmit: (values) => {
        updatePostType({ ...values, id: data.id }).then((res) => {
          if (res && res.data) {
            refetch();
            reloadList();
            showServerSuccess(i18n("toast.success_changes_update"));
          } else {
            showServerError(res);
          }
          setModal(null);
        });
      },
      onClose: () => setModal(null),
    });
  };

  // ON UPDATE STRUCTURE
  const onUpdate = (structure) => {
    updateStructure({ id: data.id, structure }).then((res) => {
      if (res && res.data) refetch();
      else showServerError(res);
    });
  };

  // ON DELETE POST TYPE
  const onDelete = () => {
    setConfirmModal({
      isOpen: true,
      type: "DELETE",
      buttonText: i18n("button.delete"),
      title: i18n("label.delete_postType"),
      forceNotice: !!data.count,
      text: i18n("alert.postType_in_use"),
      notice: i18n("alert.postType_delete"),

      onConfirm: () => {
        deletePostType({ id: data.id }).then((res) => {
          if (res && res.data) {
            reloadList();
            navigate("/dashboard/builders/post-types");
          } else {
            showServerError(res);
          }
        });
      },
      onClose: () => setConfirmModal(null),
    });
  };

  if (!id)
    return (
      <div>
        <p>{i18n("postTypes.noItems")}</p>
      </div>
    );

  if (isFetching) return <Loading active className="h-full flex items-center justify-center" />;

  return (
    <>
      <BuilderConstructor data={{ ...data }} posttype={true} isLoading={isLoading} isError={isError} onUpdate={onUpdate} placeholder={i18n("label.build_properties")}>
        <Dropdown
          options={[
            { title: i18n("button.edit"), onClick: onEdit, icon: "edit" },
            { title: i18n("button.delete"), onClick: onDelete, icon: "delete", delete: true, disabled: data && data.key && data.key === "pages" },
          ]}
        />
      </BuilderConstructor>

      <ModalPostType {...modal} />
      <ModalConfirm {...confirmModal} />
    </>
  );
}
